import React from 'react';

function AboutNote() {
  const backgroundImage = 'ngo14.jpg'; // Demo image URL from Unsplash


  return (
    <div className="relative min-h-screen bg-cover bg-center" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="absolute inset-0 bg-black opacity-50"></div>
      <div className="flex items-center justify-center min-h-screen">
        <div className="relative z-10 p-8 bg-white bg-opacity-90  shadow-lg max-w-prose">
          <h1 className="text-4xl font-bold mb-4 text-center">About Us</h1>
          <p className="text-lg">
          At Symbolcare Services, we are dedicated to providing high-quality, personalized care and support to individuals in the comfort of their own homes. Our team of experienced and compassionate care professionals is committed to delivering exceptional care that prioritizes dignity, independence, and well-being.
          </p>
          <p className="mt-4 text-lg">
          We offer a wide range of services to cater to the diverse needs of our clients, including:</p>

<p>Personal Care: Assistance with daily activities such as bathing, dressing, grooming, and hygiene to help clients maintain their personal well-being and independence.</p>
<p>Domestic Care: Support with household tasks such as cleaning, cooking, laundry, and shopping, ensuring a safe and comfortable living environment.</p>
<p>Medication Management: Assistance with medication reminders, administration, and monitoring to ensure clients take their medications correctly and on time.</p>
<p>Respite Care: Temporary relief for primary caregivers, allowing them to take a break while ensuring their loved ones receive high-quality care in their absence.</p>
<p>Companionship: Social interaction, engaging activities, and emotional support to reduce loneliness and enhance the overall well-being of our clients.          </p>
          <p className="mt-4 text-lg">
            Thank you for visiting our project. We hope you find it useful and engaging. If you have any questions or feedback, please feel free to reach out to us. Together, we can create something amazing!
          </p>
        </div>
      </div>
    </div>
  );
}

export default AboutNote;

