import React from 'react';
import { FaEnvelope, FaPhone, FaMapMarkerAlt } from 'react-icons/fa';
import Navbar1 from '../theme/navibar';
import Footer from '../Services/footer';
import ContactUsHeroSection from '../Services/ContactSection/contactHeroSection';
// import Quicklink1 from '../Services/ContactSection/contactQuicklin';
import HeroSectionWithForm from '../theme/joinusform';
const ContactUsSection = () => {
  return (
    <>
      <Navbar1 />
      <ContactUsHeroSection />
      {/* <Quicklink1 className="z-30" /> */}
      <div className="bg-gray-100 py-12">
        <div className=" mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-8">Contact Us</h2>
          <div className="grid md:grid-cols-2 justify-center">
            {/* Contact Details */}
            <div className="w-full  lg:p-4">
              <div className="bg-white lg:p-6 rounded-lg text-center">
                <h3 className="text-xl font-semibold mb-4">Contact Details</h3>
                <div className="flex items-center justify-center mb-4">
                  <FaEnvelope className="text-blue-500 mr-2" />
                  <span>Brokersrus100@yahoo.co.uk</span>
                </div>
                <div className="flex items-center justify-center mb-4">
                  <FaPhone className="text-green-500 mr-2" />
                  <span>+447946088792</span>
                </div>
                <div className="flex items-center justify-center mb-4">
                  <FaMapMarkerAlt className="text-red-500 mr-2" />
                  <span>Salisbury Mansions Road London, London, N9 0NT, Uniked Kingdom</span>
                </div>
                {/* <div className="flex items-center justify-center">
                  <FaGlobe className="text-yellow-500 mr-2" />
                  <span>wwww.</span>
                </div> */}
              </div>
              <div  className='grid sm:grid-col-1 md:grid-cols-2'>
            <div>  <img src="takingofoldpeople.jpg" alt="Zephtech hero Art" className="object-cover w-fit h-fit" /></div>

            <div className='my-auto'> <h2             style={{ fontFamily: "calibri" }}
 className='text-xl my-auto'> Contact us to discuss your care needs and schedule an assessment. We will work with you to create a personalized care plan tailored to your specific requirements and preferences.</h2>
</div></div>

            </div>



            {/* Inquiry Form */}
            <div className="w-full  p-4">
              <div className="bg-white p-6 rounded-lg">
                <h3 className="text-xl font-semibold mb-4 text-center">Inquiry Form</h3>
                <form className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700" htmlFor="name">Name</label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700" htmlFor="email">Email</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                 
                  <div>
                    <label className="block text-sm font-medium text-gray-700" htmlFor="description">Description</label>
                    <textarea
                      id="description"
                      name="description"
                      rows="4"
                      className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                    ></textarea>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700" htmlFor="location">Location</label>
                    <input
                      type="text"
                      id="location"
                      name="location"
                      className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                  <div className="text-center">
                    <button
                      type="submit"
                      className="inline-block px-6 py-2 bg-orange-800 text-white font-semibold rounded-md shadow-md hover:bg-orange-700 transition duration-300"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <HeroSectionWithForm />
          {/* Google Map */}
          <div className="w-full h-[100%] p-4">
            <div className="bg-white p-6 rounded-lg">
              <h3 className="text-xl font-semibold mb-4 text-center">Our Location</h3>
              <div className="aspect-w-16 aspect-h-9">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.835434508254!2d144.95373531531635!3d-37.816279779751494!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642af0f11fd81%3A0xf577ef77b3b0d9b0!2sFlinders%20St%20Station!5e0!3m2!1sen!2sau!4v1625757472158!5m2!1sen!2sau"
                  width="100%"
                  height="100%"
                  allowFullScreen=""
                  loading="lazy"
                  title="Google Map"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ContactUsSection;
