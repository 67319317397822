import React from 'react';


const AboutHero = () => {
  return (
    <div className="bg-white py-12 md:py-20 lg:py-40">
      <div className=" lg:mx-[10%] md:mx-[7%] mx-[5%]  px-4 flex flex-wrap items-center">
        <div className="w-full lg:w-1/2 px-4 mt-8 lg:mt-0">
          <div>
            <h1 className="lg:mt-4 md:mt-2 mt-2 text-3xl font-bold text-left  text-gray-900">Get to know Us</h1>
            <p className='lg:mt-4 md:mt-2 mt-2  text-md lg:mt-4 md:mt-2 mt-2 text-left   text-md md:text-lg lg:text-lg text-gray-900 '>At Symbolcare Services, we are dedicated to providing high-quality, personalized care and support to individuals in the comfort of their own homes. Our team of experienced and compassionate care professionals is committed to delivering exceptional care that prioritizes dignity, independence, and well-being. We offer a range of services including personal care, domestic care, medication management, respite care, and companionship. Our values of person-centered care, compassion, trust, flexibility,
              and continuous improvement guide everything we do, ensuring we provide the best possible care for you or your loved one.</p>
          </div>
        </div>
        <div className="w-full lg:w-1/2 px-4">
          <div className='gap-4'>

            <div className="w-full md:w-1/2 grid grid-cols-2 gap-4">
              <div className="w-48 h-48 bg-gray-300 flex items-center rounded  justify-center">
                <img src="ngo12.jpg" alt="Zephtech hero Art " className="object-cover mx-auto rounded w-full h-full" />
              </div>
              <div className="w-fit md:w-48 h-fit md:h-48 bg-gray-300 flex items-center rounded-md justify-center">
                <img src="kidsspecialty.jpg" alt="Placeholder 2" className="object-cover rounded-md w-full h-full" />
              </div>
              <div className="md:w-full w-fit h-fit md:h-48 bg-gray-300 flex items-center justify-center">
                <img src="takingofoldpeople.jpg" alt="Zephtech hero Art" className="object-cover w-full h-full" />
              </div>
              <div className="w-fit md:w-full h-fit md:h-60 bg-gray-300 flex items-center justify-center">
                <img src="kidswithpot.jpg" alt="Zephtech hero Art " className="object-cover  p-4 w-full h-full" />
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default AboutHero;
