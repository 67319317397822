import React from 'react';
import { FaLaptopCode, FaUsers, FaRocket, FaHandsHelping } from 'react-icons/fa';

const AboutSection1 = () => {
  return (
    <div className="bg-gray-100 ">
      <div className="relative h-[70rem] md:h-[20rem]  mx-auto px-4">
        <div className="flex flex-wrap absolute  justify-center mx-[10%] -top-6 bg-white">
          <div className="w-full sm:w-1/2 lg:w-1/4 p-4">
            <div className="bg-white p-6  shadow-lg text-center border-l-2 border-gray-400">
              <FaLaptopCode className="text-4xl text-blue-500 mb-4 mx-auto" />
              <h3 className="text-xl font-semibold mb-2">Our Mission</h3>
              <p className="text-gray-600">To deliver high-quality software solutions that empower our users and solve real-world problems.</p>
            </div>
          </div>
          <div className="w-full sm:w-1/2 lg:w-1/4 p-4">
            <div className="bg-white p-6  shadow-lg text-center border-l-2 border-gray-400">
              <FaUsers className="text-4xl text-green-500 mb-4 mx-auto" />
              <h3 className="text-xl font-semibold mb-2">Our Team</h3>
              <p className="text-gray-600">A dedicated team of professionals with diverse skills and a passion for technology and innovation.</p>
            </div>
          </div>
          <div className="w-full sm:w-1/2 lg:w-1/4 p-4">
            <div className="bg-white p-6 shadow-lg text-center border-l-2 border-gray-400">
              <FaRocket className="text-4xl text-red-500 mb-4 mx-auto" />
              <h3 className="text-xl font-semibold mb-2">Our Vision</h3>
              <p className="text-gray-600">To be a leading provider of innovative software solutions that drive progress and success.</p>
            </div>
          </div>
          <div className="w-full sm:w-1/2 lg:w-1/4 p-4">
            <div className="bg-white p-6  shadow-lg text-center border-l-2 border-gray-400">
              <FaHandsHelping className="text-4xl text-yellow-500 mb-4 mx-auto" />
              <h3 className="text-xl font-semibold mb-2">Our Values</h3>
              <p className="text-gray-600">Commitment to quality, collaboration, and continuous improvement in everything we do.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutSection1;
