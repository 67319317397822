import React from 'react';

const Services = () => {
    return (
        <>
            <div className='w-screen max-auto h-full bg-gray-50 '>
                
            <h3 className="text-[2rem] font-bold pt-[7rem]">What We Do</h3>
                <p className="text-sm pb-4">Our Services</p>
                <div className='bg-orange-800 mx-auto h-2 w-[10rem]'></div>
                <div className='grid lg:grid-cols-4 md:grid-cols-2  gap-4 px-4  items-center py-6 w-screen h-full justify-between'>
                    <div className='w-fit  h-fit bg-white shadow-sm m-4 group'>
                        <div className='relative overflow-hidden'>
                            <img src='takingcare.jpg' alt='iaid' className='h-[70%] w-full p-4 ' />
                            <div className='absolute h-full w-full bg-black/20 flex item-center justify-center -bottom-10 group-hover:-bottom-0 opacity-0 group-hover:opacity-100 transition-all duration-300'>
                                <div className='mt-8'>
                                <a href='ErrorPage' className='bg-black text-white py-2 px-4  mt-4 mx-4 hover:bg-orange-800'>Start</a>
                                    <h1 className='text-md text-gray-100  hover:bg-black p-2 mx-4'>Assistance with daily living activities such as bathing, dressing, and grooming.</h1>
                                    </div>

                            </div>
                        </div>
                        <h1 className='text-xl text-gray-900 py-2 font-semibold'>Personal Care</h1>
                       
                    </div>
                    
                    <div className='w-fit h-fit bg-white shadow-sm m-4 group'>
                        <div className='relative overflow-hidden'>
                            <img src='domasitcicare.jpg' alt='iaid' className='h-[70%] w-full p-4 ' />
                            <div className='absolute h-full w-full bg-black/20 flex item-center justify-center -bottom-10 group-hover:-bottom-0 opacity-0 group-hover:opacity-100 transition-all duration-300'>
                                <div className='mt-8'>
                                <a href='ErrorPage' className='bg-black text-white py-2 px-4  mt-4 mx-4 hover:bg-orange-800'>Start</a>
                                    <h1 className='text-md text-gray-100 mx-4 hover:bg-black p-2'>Help with household tasks like cleaning, laundry, and meal preparation.</h1>
                                    </div>

                            </div>
                        </div>
                        <h1 className='text-xl text-gray-900 py-2 font-semibold'>Domestic Care</h1>
                       
                    </div>
                    
                    <div className='w-fit h-fit bg-white shadow-sm m-4 group'>
                        <div className='relative overflow-hidden'>
                            <img src='givingdrugs.jpeg' alt='iaid' className='h-[70%] w-full p-4 ' />
                            <div className='absolute h-full w-full bg-black/20 flex item-center justify-center -bottom-10 group-hover:-bottom-0 opacity-0 group-hover:opacity-100 transition-all duration-300'>
                                <div className='mt-8'>
                                <a href='ErrorPage' className='bg-black text-white py-2 px-4  mt-4 mx-4 hover:bg-orange-800'>Start</a>
                                    <h1 className='text-md text-gray-100 mx-4 hover:bg-black p-2'>Support with medication reminders and administration</h1>
                                    </div>

                            </div>
                        </div>
                        <h1 className='text-xl text-gray-900 py-2 font-semibold'>Medication Management</h1>
                       
                    </div>
                    
                    <div className='w-fit h-fit bg-white shadow-sm m-4 group'>
                        <div className='relative overflow-hidden'>
                            <img src='repitra.jpg' alt='iaid' className='h-[70%] w-full p-4 ' />
                            <div className='absolute h-full w-full bg-black/20 flex item-center justify-center -bottom-10 group-hover:-bottom-0 opacity-0 group-hover:opacity-100 transition-all duration-300'>
                                <div className='mt-8'>
                                <a href='ErrorPage' className='bg-black text-white py-2 px-4  mt-4 mx-4 hover:bg-orange-800'>Start</a>
                                    <h1 className='text-md text-gray-100 mx-4 hover:bg-black p-2'>Temporary relief for family caregivers</h1>
                                    </div>

                            </div>
                        </div>
                        <h1 className='text-xl text-gray-900 py-2 font-semibold'>Respite Care</h1> 
                </div>
                </div>

        </div>
        </>


        );
      };
      
      export default Services;
      