import React from 'react';
import { FaGlobe, FaShoppingCart, FaSchool, FaHeartbeat, FaUserTie } from 'react-icons/fa';
import 'tailwindcss/tailwind.css';

const bundles = [
  {
    id: 1,
    icon: <FaGlobe size={50} />,
    title: 'Static Website',
    price: '$199',
    description: 'A fast, secure static website for your business or personal use.',
  },
  {
    id: 2,
    icon: <FaShoppingCart size={50} />,
    title: 'E-Commerce Web App',
    price: '$999',
    description: 'A complete e-commerce solution with payment integration and more.',
  },
  {
    id: 3,
    icon: <FaSchool size={50} />,
    title: 'School Portal Web App',
    price: '$799',
    description: 'A school portal for managing students, teachers, and classes efficiently.',
  },
  {
    id: 4,
    icon: <FaHeartbeat size={50} />,
    title: 'Medical Support Web App',
    price: '$899',
    description: 'A medical support app to manage appointments and patient records.',
  },
  {
    id: 5,
    icon: <FaUserTie size={50} />,
    title: 'HR Web App',
    price: '$699',
    description: 'An HR management system to streamline your employee management processes.',
  },
];

const BundleCard = ({ icon, title, price, description }) => (
  <div className="bg-white shadow-md rounded-lg p-6 m-4 transform transition duration-500 hover:scale-105">
    <div className="flex justify-center mb-4">{icon}</div>
    <h2 className="text-2xl font-bold mb-2 text-center">{title}</h2>
    <p className="text-xl text-center text-gray-600 mb-4">{price}</p>
    <p className="text-gray-700 text-center">{description}</p>
  </div>
);

const BundlesDisplay = () => (
  <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100 py-12">
    <h1 className="text-4xl font-bold mb-12">Our Bundles</h1>
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
      {bundles.map(bundle => (
        <BundleCard
          key={bundle.id}
          icon={bundle.icon}
          title={bundle.title}
          price={bundle.price}
          description={bundle.description}
        />
      ))}
    </div>
  </div>
);

export default BundlesDisplay;
 