import React from 'react';
import AboutNote from '../Services/AboutSection/aboutnote';
import AboutSection1 from '../Services/AboutSection/ourcorevalue';
import TeamSection from '../Services/AboutSection/ourteam';
import Footer from '../Services/footer';
import Navbar1 from '../theme/navibar';
import AboutHero from '../Services/AboutSection/AboutHeroPage';
import Donate from '../theme/Donate';
import FeatureSection from '../Services/numberofworkdone';
function About() {
  return (
    <div >
      <Navbar1 />
      <AboutHero className="relative" />
      <AboutNote className="relative" />
      <AboutSection1 className="relative" />
      <Donate className="relative" />
      <TeamSection />
      <FeatureSection />
      <Footer />
    </div>
  );
}

export default About;
