import React from 'react';

const HeroSection = () => {
  return (
    <section className="bg-gray-100 md:pt-40 pt-[4.5rem] pb-40">
      <div className=" mx-[1px] md:mx-[10%] flex flex-col md:flex-row items-center px-[2px] md:px-4">
        {/* Image Tiles Section */}

        {/* Text Section */}
        <div className="relative w-full md:w-1/2 z-10 mb-12 bg-black md:bg-gray-100 md:mb-0">

          <div className="w-full md:w-1/2 md:hidden -z-20 absolute block">
            <img src="takingcare.jpg" alt="Zephtech hero Art " className="object-cover mx-auto  bg-black opacity-30  md-hidden w-full h-full" />
            <div className=" inset-0 bg-black opacity-70"></div>
          </div>
          <h2 className="text-lg text-orange-800 md:shadow-white shadow-md font-semibold px-[10%] md:px-[1px]  text-left"> Welcome to Symbolcare Services</h2>
          <h1 className="text-4xl md:text-6xl text-4xl font-bold px-[10%] md:px-[1px]  text-gray-50  md:text-gray-900 mb-4 text-left">Compassionate, Personalized Home Care</h1>
          <p className="md:text-gray-700  text-gray-50 text-left text-sm md:text-lg px-[10%] md:px-[1px] mb-8">
            At Symbolcare Services, we are dedicated to providing high-quality,
            personalized care and support to individuals in the comfort of their own homes.
            Our team of experienced and compassionate care professionals is committed to delivering exceptional
            care that prioritizes dignity, independence, and well-being.          </p>
          <div className="grid md:grid-cols-2 px-[10%] md:px-[1px] space-x-4">
            <button className="bg-orange-800 text-white px-4 py-2  shadow hover:bg-gray-700">
              Get Started
            </button>
            <button className="0 text-amber-400 px-4 py-2 rounded  shadow flex items-center hover:text-orange-800">
              <span className=' text-gray-200 md:text-gray-800'>Learn More</span>
              <svg className="ml-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12.414V13a1 1 0 11-2 0V5.586l-2.293 2.293a1 1 0 11-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 5.586z" clipRule="evenodd" />
              </svg>
            </button>
          </div>
        </div>

        {/* Image Tiles Section */}
        <div className="w-full md:w-1/2 grid grid-cols-2 gap-4">
          <div className="w-48 h-48 bg-gray-300 flex items-center rounded  justify-center">
            <img src="africankidsandwhiteeeeee.jpeg" alt="Zephtech hero Art " className="object-cover mx-auto rounded w-full h-full" />
          </div>
          <div className="w-fit md:w-48 h-fit md:h-48 bg-gray-300 flex items-center rounded-md justify-center">
            <img src="kidsspecialty.jpg" alt="Placeholder 2" className="object-cover rounded-md w-full h-full" />
          </div>
          <div className="w-full h-48 bg-gray-300 flex items-center justify-center">
            <img src="takingofoldpeople.jpg" alt="Zephtech hero Art" className="object-cover w-full h-full" />
          </div>
          <div className="w-fit md:w-full h-fit md:h-60 bg-gray-300 flex items-center justify-center">
            <img src="kidswithpot.jpg" alt="Zephtech hero Art " className="object-cover  p-4 w-full h-full" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
