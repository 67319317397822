import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './Components/Home';
import About from './Components/About';
import ContactUsSection from './Components/Contact';
import Webdev1 from './Services/webdevelopment';
import Blog from './Components/Blog';
import GetInvolved from './Components/Getinvolved';
import ErrorPage from './Components/ErrorPage';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/Contact" element={<ContactUsSection />} />
          <Route path="/webdevelopment" element={<Webdev1 />} />
          <Route path="/Blog" element={<Blog />} />
          <Route path="/GetInvolved" element={<GetInvolved />} />
          <Route path="/ErrorPage" element={<ErrorPage />} />

        </Routes>
      </div>
    </Router>
  );
}

export default App;







