import React from 'react';
import HeroSection from '../Services/GetInvolved/herosection';
import Footer from '../Services/footer';
import Navbar1 from '../theme/navibar';
import MoreRection from '../theme/terms';
import HeroSectionWithForm from '../theme/joinusform';
import FeatureSection from '../Services/numberofworkdone';

function GetInvolved() {
    return (
        <div >
            <Navbar1 />
            <HeroSection />
            <MoreRection />
            <HeroSectionWithForm />
            <FeatureSection />
            <Footer />
        </div>
    );
}

export default GetInvolved;
