import React from 'react';
import HeroSection from '../Services/hero';
import Navbar1 from '../theme/navibar';
import ServicesSection from '../Services/services';
import Services from '../Services/Server';
import Footer from '../Services/footer';
import FeatureSection from '../Services/numberofworkdone';
import TestimonialSection from '../Services/testmonial';
import FAQSection from '../Services/fsq';
import PreviousWorkSection from '../Services/exploreourwork';
// import CompaniesSection from '../Services/logosection';
function Home() {
  return (
    <div className="App bg-gray-900">

      <Navbar1 className="relative" />
      <HeroSection className="relative" />
      <div className=''>
        {/* <h1 className='text-white text-xl font-bold bg-gray-500'>Our Services</h1> */}
        <ServicesSection />
        <Services />
        <PreviousWorkSection />
        <FAQSection />
        <TestimonialSection />
        <FeatureSection />
        {/* <CompaniesSection /> */}
        <Footer />
      </div>
    </div>
  );
}

export default Home;
