import React from 'react';
import { FaExternalLinkAlt } from 'react-icons/fa';

const PreviousWorkSection = () => {
  // Dummy data for demonstration
  const works = [
    {
      title: 'News Loading',
      imageUrl: 'loading.png',
      link: '#'
    },
    {
      title: 'News Loading',
      imageUrl: 'loading.png',
      link: '#'
    },
    {
      title: 'News Loading',
      imageUrl: 'loading.png',
      link: '#'
    }
  ];

  return (
    <section className="py-12 bg-gray-100">
      <div className=" mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-8">New And Update</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
          {works.map((work, index) => (
            <div key={index} className="bg-white  overflow-hidden">
              <div class="border border-orange-800 shadow rounded-md p-4 max-w-sm w-full mx-auto">
  <div class="animate-pulse flex space-x-4">
    <div class="rounded-full bg-slate-700 h-10 w-10"></div>
    <div class="flex-1 space-y-6 py-1">
      <div class="h-2 bg-slate-700 rounded"></div>
      <div class="space-y-3">
        <div class="grid grid-cols-3 gap-4">
          <div class="h-2 bg-slate-700 rounded col-span-2"></div>
          <div class="h-2 bg-slate-700 rounded col-span-1"></div>
        </div>
        <div class="h-2 bg-slate-700 rounded"></div>
      </div>
    </div>
  </div>
</div>
              <div className="p-4 bg-orange-800">
              <svg class="animate-spin h-5 w-5 mr-3 ..." viewBox="0 0 24 24">.....</svg>  <h3 className="text-xl font-semibold mb-2">{work.title}</h3>
                <a
                  href={work.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-orange-600 hover:text-orange-400 flex items-center mb-4"
                >
                  <FaExternalLinkAlt className="mr-2" />
                  View Project
                </a>
              </div>
            </div>
          ))}
        </div>
        <div className="text-center mt-8">
          <a
            href="/ErrorPage"
            className="inline-block bg-orange-800 text-white px-6 py-2 shadow-md hover:bg-orange-500 transition duration-300"
          >
            More Update
          </a>
        </div>
      </div>
    </section>
  );
};

export default PreviousWorkSection;
