import React from 'react';

const HeroSectionWithForm = () => {
    return (
        <section
            className="relative flex flex-col md:flex-row items-center justify-between p-6 md:p-12 bg-cover bg-center"
            style={{ backgroundImage: 'url(bg1.jpeg)' }}
        >
            {/* Overlay to darken the background image for better text visibility */}
            <div className="absolute inset-0 bg-black opacity-50"></div>

            <div className="relative z-10 md:w-1/2 bg-white bg-opacity-30 backdrop-blur-md p-6 m-2">
                <h2 className="text-xl text-gray-200 mb-2">We look forward to the opportunity to serve you.</h2>
                <h1 className="text-4xl md:text-5xl font-bold text-white mb-4">Get in Touch</h1>
                <p className="text-lg text-gray-300 mb-6">

If you or a loved one could benefit from our services, please don't hesitate to contact us. We are here to answer any questions you may have and to help you get started with a personalized care plan tailored to your unique needs.</p>
            </div>

            <div className="relative z-10 md:w-1/2 bg-white bg-opacity-30 backdrop-blur-md p-6 m-2">
                <form className="space-y-4">
                    <div>
                        <label htmlFor="name" className="block text-white">Name</label>
                        <input
                            type="text"
                            id="name"
                            className="w-full px-4 py-2 mt-2 focus:outline-none focus:ring-2 focus:ring-orange-700"
                        />
                    </div>
                    <div>
                        <label htmlFor="email" className="block text-white">Email</label>
                        <input
                            type="email"
                            id="email"
                            className="w-full px-4 py-2 mt-2 focus:outline-none focus:ring-2 focus:ring-orange-700"
                        />
                    </div>
                    <div>
                        <label htmlFor="address" className="block text-white">Address</label>
                        <input
                            type="text"
                            id="address"
                            className="w-full px-4 py-2 mt-2 focus:outline-none focus:ring-2 focus:ring-orange-700"
                        />
                    </div>
                    <button
                        type="submit"
                        className="w-full bg-orange-700 text-white py-2 px-4 hover:bg-orange-800 transition duration-300"
                    >
                        Submit
                    </button>
                </form>
            </div>
        </section>
    );
}

export default HeroSectionWithForm;
