import React from "react";
const MoreRection = () => {
    const data = [
        {
            src: "images/logo",
            Header: "Become a Care Partner",
            Paragraph:
                "Be a force for compassion and support! Join us in making a meaningful difference in the lives of individuals and families by becoming a valued care partner at Symbolcare Services. Your support will empower us to deliver exceptional care, enhance well-being, and foster independence and dignity for those we serve.",
        },

        {
            src: "images/logo",
            Header: "Become a Volunteer",
            Paragraph:
                "Be a beacon of hope and kindness! Join us in making a significant impact on the lives of individuals and families by becoming a dedicated volunteer at Symbolcare Services. Your time and effort will help us provide exceptional care, promote well-being, and support the independence and dignity of those we serve.",
        },

        {
            src: "images/logo",
            Header: "Become a Benefactor",
            Paragraph:
                "Be a champion of care and support! By becoming a benefactor at Symbolcare Services, you can help us deliver exceptional care and improve the lives of individuals and families in our community. Your generous contributions will enable us to provide personalized care, promote well-being, and support the independence and dignity of those we serve.",
        },
    ];
    return (
        <div className=" md:flex justify-between ">
            {data.map((item, index) => (
                <div
                    key={index}
                    data-aos="fade-up"
                    data-aos-anchor-placement="top-center"
                    className=" text-white p-6 md:p-12 font-sans  w-full"
                    style={{
                        backgroundImage: "linear-gradient(to left, #c2410c ,#7c2d12",
                        fontFamily: "calibri",
                    }}
                >
                    <div>
                        <div className="text-lg font-semibold pb-2 code">{item.Header}</div>
                        <div className="text-sm text-zinc-200">{item.Paragraph}</div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default MoreRection;




