import React from 'react';
import {  FaPaintBrush, FaChartBar, FaTools } from 'react-icons/fa';

const FeatureSection = () => {
  // Dummy data for demonstration
  const features = [

    { title: 'TOTAL PROJECT', count: 345, icon: <FaTools /> },
    { title: 'TOTAL VOLUNTERS', count: 456, icon: <FaPaintBrush /> },
    { title: 'TOTAL AWARDS', count: 678, icon: <FaChartBar /> }
  ];

  return (
    <section className="py-12 bg-gray-100">
      <div className="mx-auto px-4 w-screen">
        <h4 className='text-lg text-orange-900 p-2'>We are serverd since 10 years to helpless people with trust and we are happy</h4>
        <a href='ErrorPage' className='bg-orange-900 text-gray-200 m-4 hover:bg-orange-700 px-4 py-2 m-2'>BE A PART OF US</a>
        <div className="mx-auto  grid grid-cols-1 my-8 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4">
          {features.map((feature, index) => (
            <div key={index} className="flex flex-col gap-4 place-items-center">
              <div className="relative bg-orange-900 w-20 h-20 rounded-full flex justify-center items-center mb-4">
                <span className="text-white text-4xl font-bold">{feature.count}</span>
              </div>
              <div className="flex items-center mb-2">
                <span className="mr-2">{feature.icon}</span>
                <span className="font-bold">{feature.title}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FeatureSection;
