import React from 'react';
import { FaTwitter, FaLinkedin, FaGithub } from 'react-icons/fa';

const teamMembers = [
  {
    name: 'John Doe',
    position: 'CEO',
    image: 'https://via.placeholder.com/150',
    twitter: '#',
    linkedin: '#',
    github: '#',
  },
  {
    name: 'Jane Smith',
    position: 'CTO',
    image: 'https://via.placeholder.com/150',
    twitter: '#',
    linkedin: '#',
    github: '#',
  },
  {
    name: 'Mike Johnson',
    position: 'Lead Developer',
    image: 'https://via.placeholder.com/150',
    twitter: '#',
    linkedin: '#',
    github: '#',
  },
  {
    name: 'Emily Davis',
    position: 'Project Manager',
    image: 'https://via.placeholder.com/150',
    twitter: '#',
    linkedin: '#',
    github: '#',
  },
];

const TeamSection = () => {
  return (
    <div className="bg-gray-100 border-b-2  border-orange-800 py-12">
      <div className=" mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-8">Our Team</h2>
        <div className="flex flex-wrap justify-center">
          {teamMembers.map((member, index) => (
            <div
              key={index}
              className="w-full sm:w-1/2 lg:w-1/4 p-4 transform transition duration-500 hover:scale-105"
            >
              <div className="bg-white p-6  shadow-lg text-center">
                <img
                  className="w-24 h-24 rounded-full mx-auto mb-4"
                  src={member.image}
                  alt={member.name}
                />
                <h3 className="text-xl font-semibold mb-2">{member.name}</h3>
                <p className="text-gray-600">{member.position}</p>
                <div className="mt-4 flex justify-center space-x-4">
                  <a href={member.twitter} className="text-orange-900 hover:text-blue-700">
                    <FaTwitter size={24} />
                  </a>
                  <a href={member.linkedin} className="text-orange-900 hover:text-blue-900">
                    <FaLinkedin size={24} />
                  </a>
                  <a href={member.github} className="text-orange-900 hover:text-gray-900">
                    <FaGithub size={24} />
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
        <p>Thank you for considering Symbolcare Services. We look forward to the opportunity to serve you</p>
      </div>
    </div>
  );
};

export default TeamSection;
