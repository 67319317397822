import React from 'react';

const HeroSection = () => {
    return (
        <section className="flex flex-col-reverse md:flex-row items-center justify-between mt-[5rem] px-6 md:py-24 bg-gray-100">
            <div className="md:w-1/2 flex flex-col items-start md:items-start px-10 text-center md:text-left">
                <h2 className="text-xl text-orange-800 mb-2">Build a community of care and kindness, making every moment matter</h2>
                <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-4">Change and a brighter future for everyone in need</h1>
                <p style={{ fontFamily: "calibri" }} className="text-lg text-gray-600 mb-6">Together, we can create a community of compassion and care, making a difference one person at a time.</p>
                <button className="bg-orange-900 hover:bg-orange-600 text-white py-2 mx-auto md:mx-2 mb-8 px-4 ">Join Us</button>
            </div>
            <div className="md:w-1/2 mb-6 md:mb-0 flex justify-center">
                <div className="relative w-3/4 h-3/4 md:w-full md:h-full">
                    <img
                        src="ngo22.jpg"
                        alt="Hero"
                        className="rounded-t-full rounded-b-lg shadow-lg"
                    />
                </div>
            </div>
        </section>
    );
}

export default HeroSection;
