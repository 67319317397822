import React from 'react';

import Footer from '../Services/footer';
import Navbar1 from '../theme/navibar';
import BlogPage from '../Services/Blog/fullblog';
function Blog() {
    return (
        <div >
            <Navbar1 />
            <BlogPage />
            <Footer />
        </div>
    );
}

export default Blog;
