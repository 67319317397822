import React from 'react';

const Donate = () => {


    return (
        <div className="w-fit bg-orange-800 py-12 text-white shadow-md">
            <h4 className='text-2xl font-bold'>Why Choose Symbolcare Services?</h4>

<p className='font-semi-bold mx-4 md:mx-[20%] lg:mx-[25%]'>Choosing Symbolcare Services means choosing a team that genuinely cares about your well-being. Our experienced professionals are dedicated to making a positive difference in your life by providing personalized, high-quality care. We strive to create a supportive and nurturing environment that fosters independence, dignity, and happiness.</p>
            <button className='bg-gray-200 px-4 mt-2  py-2 text-md text-orange-900'>Donate</button>
        </div>
    );
};

export default Donate;
