import React from 'react';

const TestimonialSection = () => {
  // Dummy data for demonstration
  const testimonials = [
    {
      name: 'John Doe',
      role: 'CEO, Company A',
      testimonial: 'This service is fantastic! It has truly transformed our business operations.',
      avatar: 'https://randomuser.me/api/portraits/men/32.jpg'
    },
    {
      name: 'Jane Smith',
      role: 'CTO, Company B',
      testimonial: 'The team is highly professional and the results are always amazing!',
      avatar: 'https://randomuser.me/api/portraits/women/44.jpg'
    },
    {
      name: 'Michael Johnson',
      role: 'Marketing Head, Company C',
      testimonial: 'Our marketing campaigns have never been more effective thanks to their insights.',
      avatar: 'https://randomuser.me/api/portraits/men/86.jpg'
    }
  ];

  return (
    <section className="py-12 bg-orange-900 text-white">
      <div className="mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-2">Testimonial</h2>
        <div className='h-[2px] mx-auto bg-white w-[12rem] mb-8'></div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {testimonials.map((testimonial, index) => (
            <div
              key={index}
              className="bg-gray-200/75 p-6  shadow-lg transform hover:scale-105 transition-transform duration-300 ease-in-out"
            >
              <div className="flex items-center mb-4">
                <img
                  src={testimonial.avatar}
                  alt={testimonial.name}
                  className="w-16 h-16 rounded-full mr-4 border-2 border-orange-800"
                />
                <div>
                  <h5 className="text-xl font-bold">{testimonial.name}</h5>
                  <p className="text-sm">{testimonial.role}</p>
                </div>
              </div>
              <p className="text-gray-300">{testimonial.testimonial}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TestimonialSection;
