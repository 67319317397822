import React from 'react';
import TestimonialSection from '../testmonial';
import CompaniesSection from '../logosection';
const BlogPage = () => {
    return (
        <div>
            {/* Hero Section */}
            <section
                className="relative flex items-center justify-center h-96 bg-cover bg-center text-white"
                style={{ backgroundImage: 'url(https://via.placeholder.com/1200x600)' }}
            >
                <div className="absolute inset-0 bg-black bg-opacity-50"></div>
                <div className="relative z-10 text-center p-6">
                    <h1 className="text-4xl md:text-6xl font-bold">Charity Blog</h1>
                    <p className="mt-4 text-lg md:text-2xl">Stories of Hope and Humanity</p>
                </div>
            </section>

            {/* Blog Section */}
            <section className="p-6 md:p-12">
                <h2 className="text-3xl md:text-4xl font-bold mb-6">Latest Blogs</h2>
                <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
                    {demoBlogs.map((blog) => (
                        <div key={blog.id} className="bg-white shadow-md overflow-hidden">
                            <img src={blog.image} alt={blog.title} className="w-full h-48 object-cover" />
                            <div className="p-4">
                                <h3 className="text-xl font-bold">{blog.title}</h3>
                                <p className="mt-2 text-gray-600">{blog.excerpt}</p>
                                <button className="mt-4 text-orange-900 hover:underline">Read more</button>
                            </div>
                        </div>
                    ))}
                </div>
            </section>

            {/* Popular Blogs Section */}
            <section className="p-6 md:p-12 bg-gray-100">
                <h2 className="text-3xl md:text-4xl font-bold mb-6">Popular Blogs</h2>
                <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
                    {popularBlogs.map((blog) => (
                        <div key={blog.id} className="bg-white rounded-lg shadow-md overflow-hidden">
                            <img src={blog.image} alt={blog.title} className="w-full h-48 object-cover" />
                            <div className="p-4">
                                <h3 className="text-xl font-bold">{blog.title}</h3>
                                <p className="mt-2 text-gray-600">{blog.excerpt}</p>
                                <button className="mt-4 text-orange-900 hover:underline">Read more</button>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
            <TestimonialSection />
            <CompaniesSection />
            {/* Comment Section */}
            {/* <section className="p-6 md:p-12">
                <h2 className="text-3xl md:text-4xl font-bold mb-6">Comments</h2>
                <div className="bg-white rounded-lg shadow-md p-6">
                    <form className="space-y-4">
                        <div>
                            <label htmlFor="name" className="block text-gray-700">Name</label>
                            <input
                                type="text"
                                id="name"
                                className="w-full px-4 py-2 mt-2 rounded-md border focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                        </div>
                        <div>
                            <label htmlFor="comment" className="block text-gray-700">Comment</label>
                            <textarea
                                id="comment"
                                className="w-full px-4 py-2 mt-2 rounded-md border focus:outline-none focus:ring-2 focus:ring-blue-500"
                                rows="4"
                            ></textarea>
                        </div>
                        <button
                            type="submit"
                            className="w-full bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition duration-300"
                        >
                            Submit
                        </button>
                    </form>
                </div>
            </section> */}
        </div>
    );
};

// Demo data sets
const demoBlogs = [
    {
        id: 1,
        title: 'Helping Hands',
        excerpt: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        image: 'https://via.placeholder.com/600x400',
    },
    {
        id: 2,
        title: 'Charity Event Success',
        excerpt: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        image: 'https://via.placeholder.com/600x400',
    },
    {
        id: 3,
        title: 'Volunteers Needed',
        excerpt: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip.',
        image: 'https://via.placeholder.com/600x400',
    },
];

const popularBlogs = [
    {
        id: 1,
        title: 'Our Impact in 2023',
        excerpt: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        image: 'https://via.placeholder.com/600x400',
    },
    {
        id: 2,
        title: 'Meet Our Volunteers',
        excerpt: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        image: 'https://via.placeholder.com/600x400',
    },
    {
        id: 3,
        title: 'How to Get Involved',
        excerpt: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip.',
        image: 'https://via.placeholder.com/600x400',
    },
];

export default BlogPage;
