import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const FAQSection = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  // Dummy data for demonstration
  const faqs = [
    {
      question: ' What is Symbolcare Services?',
      answer: 'Symbolcare Services is a dedicated provider of high-quality, personalized care and support for individuals in the comfort of their own homes. Our experienced and compassionate care professionals prioritize dignity, independence, and well-being in all aspects of our services.'
    },
    {
      question: 'What types of services does Symbolcare Services offer?',
      answer: 'We offer a range of services, including personal care, domestic care, medication management, respite care, and companionship. Our goal is to meet the diverse needs of our clients and provide comprehensive support.'
    },
    {
      question: ' What is personal care?',
      answer: 'Personal care includes assistance with daily activities such as bathing, dressing, grooming, and hygiene. Our caregivers provide respectful and sensitive support to help clients maintain their personal well-being and independence'
    },
    {
      question: 'How does Symbolcare Services manage medication?',
      answer: 'Our medication management service includes assistance with medication reminders, administration, and monitoring. Our caregivers ensure that clients take their medications correctly and on time, following the prescribed guidelines'
    },
    {
      question: ' What values guide Symbolcare Services?',
      answer: 'Our values include person-centered care, compassion, trust, flexibility, and continuous improvement. These principles guide everything we do, ensuring that we provide the best possible care for our clients.'
    }
  ];

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section className="relative bg-cover bg-center h-screen md:h-[40rem]" style={{ backgroundImage: "url('frq.jpg')" }}>
      <div className="absolute inset-0 bg-black opacity-50"></div>
      <div className="relative mx-auto px-4 py-12 h-full flex items-center justify-center">
        <div className="bg-white bg-opacity-60  shadow-lg p-8 w-full md:w-3/4 lg:w-1/2">
          <h2 className="text-3xl font-bold text-center mb-8">Frequently Asked Questions</h2>
          <div className="space-y-4">
            {faqs.map((faq, index) => (
              <div key={index} className="border-b border-gray-300 pb-4">
                <div
                  className="flex justify-between items-center cursor-pointer"
                  onClick={() => toggleFAQ(index)}
                >
                  <h3 className="text-xl font-semibold">{faq.question}</h3>
                  {activeIndex === index ? <FaChevronUp /> : <FaChevronDown />}
                </div>
                <div
                  className={`mt-2 overflow-hidden transition-max-height duration-500 ease-in-out ${activeIndex === index ? 'max-h-screen' : 'max-h-0'}`}
                >
                  <p>{faq.answer}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQSection;
