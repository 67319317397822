import React from 'react';
import Navbar from '../theme/navibar';
import Footer from '../Services/footer';
const ErrorPage = () => {
    return (
        <>
            <Navbar />
    <div className="min-h-screen bg-gray-100 flex items-center justify-center">
      <div className="max-w-lg mx-auto bg-white p-8 shadow-md rounded-md flex items-center space-x-4">
        <div className="text-Orange-800 text-6xl font-bold">Oops!</div>
        <div>
          <h1 className="text-3xl font-semibold mb-2">Something went wrong.</h1>
          <p className="text-gray-700 mb-4">We're working hard to fix it. Please try again later.</p>
          <div className="flex items-center justify-center">
            <img src="arterro1.jpg" alt="Engineers working" className="w-48 h-48" />
          </div>
        </div>
      </div>
            </div>
            <Footer />
            </>
  );
};

export default ErrorPage;
